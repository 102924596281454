import axios from 'axios';

export default axios.create({
    //baseURL: 'http://localhost:3500'
    baseURL: 'https://events-site.pezgord8.com'

});
    //baseURL: '/api'
   //baseURL: 'https://events-site.pezgord8.com/api/'

    //baseURL: 'http://nodej-loadb-1hlll7ypgzzld-13bfa31c2b9c223c.elb.us-east-1.amazonaws.com:3500'
